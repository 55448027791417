export default [
  // *===============================================---*
  // *--------- ORGANISATIONS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/organisation/list',
    name: 'apps-organisations',
    component: () => import('@/views/pages/organisations/organisation-list/OrganisationsList.vue'),
    meta: {
      pageTitle: 'Organisaties',
      action: 'read',
      resource: 'organisation',
    },
  },
  {
    path: '/organisation/edit/:id',
    name: 'apps-organisation-edit',
    component: () => import('@/views/pages/organisations/organisation-edit/OrganisationEdit.vue'),
    meta: {
      pageTitle: 'Organisatie',
      action: 'update',
      resource: 'organisation',
    },
  },
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/pages/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Gebruikers',
      resource: 'user',
      action: 'read',
    },
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/pages/user/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Gebruiker',
      resource: 'user',
      action: 'update',
    },
  },
  // *===============================================---*
  // *--------- ACCOUNT SETTINGS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/account/settings',
    name: 'account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account',
      resource: 'account',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- REPORTS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/reports',
    name: 'apps-reports',
    component: () => import('@/views/pages/reports/Reports.vue'),
    meta: {
      pageTitle: 'Rapportage',
      resource: 'reports',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- LOGS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/log',
    name: 'apps-log',
    component: () => import('@/views/pages/log/Log.vue'),
    meta: {
      pageTitle: 'Audit log',
      resource: 'audit',
      action: 'read',
    },
  },
]
