export default [
  {
    path: '/dashboard',
    name: 'app-dashboard',
    component: () => import('@/views/pages/dashboard/Dashboard.vue'),
    meta: {
      action: 'read',
      resource: 'dashboard',
    },
  },
]
