export default [
  // *===============================================---*
  // *--------- REPORTS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/reports',
    name: 'apps-reports',
    component: () => import('@/views/pages/reports/Reports.vue'),
    meta: {
      pageTitle: 'Rapportage',
      resource: 'reports',
      action: 'read',
    },
  },
]
