export default [
  {
    path: '/gaslicht/list',
    name: 'app-gaslicht',
    component: () => import('@/views/pages/external-integration/gaslicht/Gaslicht.vue'),
    meta: {
      pageTitle: 'Gaslicht',
      action: 'read',
      resource: 'hem_external',
    },
  },
]
