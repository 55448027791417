import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  // Endpoints
  loginEndpoint: `${axiosDefaultConfig.backendEndPoint}login`,
  refreshEndpoint: `${axiosDefaultConfig.backendEndPoint}refreshtoken`,
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
