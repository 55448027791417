export default [
  {
    path: '/order/overview',
    name: 'app-order',
    component: () => import('@/views/pages/order/order-list/Orders.vue'),
    meta: {
      pageTitle: 'Orders',
      action: 'read',
      resource: 'order',
    },
  },
  {
    path: '/order/detail/:id',
    name: 'app-order-detail',
    component: () => import('@/views/pages/order/order-detail/OrderDetail.vue'),
    meta: {
      pageTitle: 'Order details',
      action: 'read',
      resource: 'order',
    },
  },
  {
    path: '/apps/order/checkout',
    name: 'apps-order-flow-checkout',
    component: () => import('@/views/pages/order-flow/OrderFlow.vue'),
    meta: {
      action: 'create',
      resource: 'order',
      pageTitle: 'Offerte',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Offerte',
        },
        {
          text: 'Nieuw',
          active: true,
        },
      ],
    },
  },
]
