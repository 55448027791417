// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: `${process.env.VUE_APP_LABEL_NAME}`, // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require,import/no-dynamic-require
    appLogoImage: require(`@/assets/images/logo/${process.env.VUE_APP_LABEL}.png`),
    // eslint-disable-next-line global-require,import/no-dynamic-require
    appLogoMarkImage: require(`@/assets/images/logo/${process.env.VUE_APP_LABEL}_mark.png`),
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'hidden', // static, sticky, hidden
    },
    customizer: false,
    enableScrollToTop: true,
  },
}
