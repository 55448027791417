export default [
  {
    path: '/product/list',
    name: 'apps-products-list',
    component: () => import('@/views/pages/products/products-list/ProductsList.vue'),
    meta: {
      pageTitle: 'Producten',
      action: 'read',
      resource: 'product',
    },
  },
  {
    path: '/product/edit/:id',
    name: 'apps-products-edit',
    component: () => import('@/views/pages/products/products-edit/ProductsEdit.vue'),
    meta: {
      pageTitle: 'Product aanpassen',
      action: 'update',
      resource: 'product',
    },
  },
  {
    path: '/flows',
    name: 'app-flows',
    component: () => import('@/views/pages/flows/list/FlowsList.vue'),
    meta: {
      pageTitle: 'Flows',
      action: 'read',
      resource: 'flows',
    },
  },
  {
    path: '/labels',
    name: 'app-labels-list',
    component: () => import('@/views/pages/supplier/supplier-list/SupplierList.vue'),
    meta: {
      pageTitle: 'Labels',
      action: 'read',
      resource: 'suppliers',
    },
  },
  {
    path: '/labels/edit/:id',
    name: 'app-labels-edit',
    component: () => import('@/views/pages/supplier/supplier-edit/SupplierEdit.vue'),
    meta: {
      pageTitle: 'Labels',
      action: 'update',
      resource: 'suppliers',
    },
  },
]
